.layout-pre-header {
    height: 40px;
}

.layout-header {
    color: $color-light;
}

.header-title {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.header-title-text {
    font-size: 1.5rem;        
    line-height: 2rem;
    padding-left: 2rem;
    margin: 0;

    
    @media (min-width: 768px) {
        font-size: 2rem;        
    }
    
    @media (min-width: 1200px) {
        font-size: 3.333rem;
        border-left: rgba(150, 160, 180, 0.25) solid 1px;
    }
}