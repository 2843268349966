.input-disabled {
    cursor: default;
    user-select: none;
    pointer-events: none;
    color: $color-grey-dark;
}


/* react-day-picker tweaks */
.DayPicker-Day {
    border-radius: 0;

    &--today {
        color: $color-blue-light;

        &.DayPicker-Day--disabled {
            color: #999999;
        }
    }

    &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: $color-blue-light;
        color: $color-light;
    }
}

.DayPickerInput {
    width: 100%;

    &-Overlay {
        top: 3px;
    }

    .form-control {
        text-transform: capitalize; // capitalize date only
        &::placeholder {
            text-transform: none;
        }
    }
    
}