blockquote {
    position: relative;
    padding: 1rem 1rem 1rem 5rem;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 900;
    background: $color-yellow;

    &::before {
        content: url('../../assets/warning.svg');
        position: absolute;
        top: 0;
        left: 1.5rem;
        bottom: 0;
        margin: auto;
        display: block;
        height: 2rem;
        width: 2rem;
        fill: #000;
    }
}