/* Date input */
.fade-enter {
    opacity: 0.01;
    height: 0px;
}
.fade-enter-active {
    opacity: 1;
    height: 77px;
    transition: all 300ms ease-in;
}
.fade-exit {
    opacity: 1;
    height: 77px;
}
.fade-exit-active {
    opacity: 0.01;
    height: 0px;
    margin-bottom: 0px;
    transition: all 300ms ease-in;
}


/* Input errors */
.error-transition {
    transform-origin: top;
    z-index: 0;
    overflow: hidden;
}

.error-enter {
    height: 0;
   
}
.error-enter-active {
    height: 2rem;
    transition: all 300ms ease-out;
}
.error-exit {
    height: 2rem;
}
.error-exit-active {
    height: 0;
    transition: all 300ms ease-out;
}
