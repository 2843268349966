.form {
    color: $color-blue-medium;
    font-weight: 500;
}

.form-group {
    label {
        margin-bottom: 0.2rem;
        font-size: 1.06667rem;
    }
}

.form-control {
    height: $input-height;
    padding: 1rem 1.333rem;
    border: none;
    border-radius: 0;
    font-size: 0.9333rem;
    font-weight: bold;
    background-color: $color-grey-light;
    color: $color-blue-dark;
    position: relative;
    margin-bottom: 0.2rem;

    &-thin {    
        padding: 1rem 0.3rem;
    }

    &:disabled {
        background-color: $color-grey-light;
    }
}


.form-col {

    &-left {
        padding: 0 15px; 
        width: calc( 100% - #{$form-col-width});
    }
    
    &-right {
        padding: 0 15px; 
        width: $form-col-width;
        line-height: $input-height;
    }
}
