ul,
ol {
    margin: 0.5rem auto 1rem;

    li {
        padding-left: 3rem;
    }

    ul,
    ol {
        margin: 1rem 0;
        padding-left: 1rem;
        li {
            padding-left: 1.5rem;
        }
        &:last-child {
            margin-bottom: 1rem;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

ol {
    > li {
        counter-increment: customlistcounter;
        position: relative;
        margin-bottom: 1.5rem;
        &:before {
            content: counter(customlistcounter) '. ';
            display: inline-block;
            position: absolute;
            top: -0.5rem;
            right: calc( 100% - 2rem );
            font-size: 2rem;
            line-height: 2rem;
            font-weight: 900;
            color: $color-grey-medium;
        }
    }
}

ul {
    li {
        position: relative;
        margin-bottom: 0.5rem;
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0.667rem;
            border-radius: 100px;
            height: 0.2667rem;
            width: 0.2667rem;
            background-color: $color-grey-medium;
        }
    }
}
