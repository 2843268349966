.logo-main {
    width: 4rem;
}

.logo-parking {
    height: 5.5rem;
    margin: -0.75rem;
    padding-right: 3rem;
}

.logo-title {
    width: 8.667rem;
    height: 40px;
}
