h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    // color: $color-blue-dark;
}

h1 {
    font-size: 3.333rem;
}

h2 {
    font-size: 1.333rem;
    font-weight: bold;
}

h3 {
    font-size: 1.0667rem;
    font-weight: 900;
}

h4 {
    font-size: 1rem;
}

