/* Bootstrap */

// margin & padding
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2.667),
    6: ($spacer * 3.333) // adds level 6 to spacing utilities - 4 rem
);

// breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1700px,
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1640px
);

// alerts
$alert-padding-y: 0.3rem;
$alert-padding-x: 0.6rem;
$alert-margin-bottom: 0;
$alert-border-radius: 0;
$alert-border-width: 0;
$alert-bg-level: -9;
$alert-border-level: -12;
$alert-color-level: 5;


/* Custom */

$input-height: 50px;
$form-col-width: 120px;


/* Colors */

$color-blue-light: #006cff;
$color-blue-medium: #334581;
$color-blue-dark: #142741;

$color-red: #ff5751;
$color-yellow: #ffeb00;
$color-light: #fff;

$color-grey-light: #eff1f4;
$color-grey-medium: #6f879f;
$color-grey-dark: #444;
