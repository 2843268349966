html {
    font-size: 15px;
    
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    color: $color-blue-dark;
}