.content {
    background: $color-light;
    padding: 2rem 1rem;

    @media (min-width: 768px) {
        padding: 2rem 3rem;
    }
    
    @media (min-width: 1200px) {
        padding: 3rem 6rem;
    }

}

.content-header {
    padding-bottom: 1rem;
    text-align: center;
}