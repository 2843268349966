.Toastify__toast {
    cursor: initial;
    margin: auto;
    min-height: 0;
    padding: 0.5rem 1rem;
    text-align: center;
}

.Toastify__toast-container--bottom-center,
.Toastify__toast-container--bottom-left,
.Toastify__toast-container--bottom-right {
    bottom: 0;
}

.Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
}
