.layout-section {
    min-height: 50vh;
    position: relative;
    // background: url('../assets/bg-alt-min.jpg');
}

.section-background {
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
}