$button-padding: 1rem;
$button-text-color: $color-light;
$button-base-color: $color-blue-light;
$button-alt-color: $color-red;

.button {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    line-height: 1rem;
    font-size: 0.8666667rem;
    font-weight: bold;
    padding: $button-padding $button-padding * 1.5;
    margin: 0 $button-padding 0 0;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
    overflow: hidden;

    &:last-child {
        margin-right: 0;
    }
}


// shape and variants
.button {
    &-large {
        display: block;
        width: 100%;
        margin: 0 auto;
    }

    &-big {
        padding: $button-padding * 1.8 $button-padding * 1.5;
    }

    &-large-text {
        font-size: 1.2rem;
    }
}


// colors & states
.button {
    background: $button-base-color;
    color: $button-text-color !important;
    user-select: none;

    &:hover {
        color: $button-text-color;
        text-decoration: none;
    }
    
    &-orange {
        background-color: $button-alt-color;
        text-transform: none;
        font-size: 1rem;
    }

    &-remove {
        position: absolute;
        right: -1rem;
        top: 0;
        margin: 0 auto;
        font-size: 1rem;
        padding: 0;
        width: 1.5rem;
        height: $input-height;
        line-height: $input-height;
        display: block;
    }

    &-disabled {
        cursor: default;
        user-select: none;
        pointer-events: none;
        background-color: $color-grey-medium;
        opacity: 0.5;
    }
}
