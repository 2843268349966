#printf {
    display: none;
}

@media print {
    #root > div > *:not(#printf) {
        display: none;
    }
    
    #printf {
        display: block !important;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
    }
    
    #printf * {
        display: initial;
    }
}