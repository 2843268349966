.fw {
    &-light {
        font-weight: 300;
    }

    &-normal {
        font-weight: 400;
    }

    &-medium {
        font-weight: 500;
    }

    &-bold {
        font-weight: 700;
    }

    &-black {
        font-weight: 900;
    }
}

.height-100 {
    height: 100%;
}
