.loading {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 200%;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    background: $color-blue-dark;
    z-index: 999;
    opacity: .8;
}

.loading-content {
    text-align: center;
    color: $color-light;
}

.loading-spinner {
    margin: 0 auto 2rem;
}